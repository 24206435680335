import { useState, useCallback, useEffect } from 'react'

import Dish from '@models/dish'

import { fetchDishes } from './menu.controller'

type FetchDishesState = {
  loading: boolean
  dishes: Dish[] | undefined
  error: Error | undefined
  reload?: () => void
}

export default (): FetchDishesState => {
  const [loading, setLoading] = useState<boolean>(true)
  const [dishes, setDishes] = useState<Dish[]>()
  const [error, setError] = useState<Error>()

  const load = useCallback(async () => {
    setLoading(true)
    try {
      const res = await fetchDishes()
      setDishes(res.data)
    } catch (error) {
      setError(error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    load()
  }, [load])

  return { loading, dishes, error, reload: load }
}
