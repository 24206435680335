import axios, { AxiosPromise } from 'axios'

import Dish from 'models/dish'

const client = axios.create({
  baseURL: process.env.GATSBY_API_URL || 'https://pita-pitakilb-backend.herokuapp.com/ic-api',
})

const addNewDish = (dish: Dish): AxiosPromise<string> => client.post('/menu', dish)

const fetchDish = (id: string): AxiosPromise<Dish | undefined> => client.get(`/menu/${id}`)

const fetchDishes = (): AxiosPromise<Dish[]> => client.get('/menu')

const updateDish = (id: string, newDish: Partial<Dish>): AxiosPromise =>
  client.put(`/menu/${id}`, newDish)

const deleteDish = (id: string): AxiosPromise => client.delete(`/menu/${id}`)

export { addNewDish, fetchDishes, fetchDish, updateDish, deleteDish }
