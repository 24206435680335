import { FC } from 'react'
import { List, Typography, Row, Col, Image, Grid, Tag, Card } from 'antd'
import _ from 'lodash'

import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'

import StyledContainer from '@root/ic-ui-react/StyledContainer'
import Dish from '@models/dish'
import { currencyFormat } from '@root/utils'
import FlexContainer from '@root/ic-ui-react/FlexContainer'

const CategoryView: FC<{ dishes: Dish[] }> = ({ dishes }) => {
  function handleRenderListItem(item: Dish): JSX.Element {
    return (
      <List.Item>
        <Card style={{ padding: 0 }}>
          {item.images && (
            <Image src={item.images[0].src} alt={item.images[0].alt} title={item.images[0].title} />
          )}
          <FlexContainer justifyContent="space-between" wrap="wrap">
            <Typography.Title level={4} style={{ margin: 0 }}>
              {_.startCase(item.name)}
            </Typography.Title>
            <Typography.Title level={5} style={{ margin: 0, color: '#096dd9' }}>
              {item.options && 'Starting at'} {currencyFormat(item.price, '$')}{' '}
              {item.uom && `/ ${item.uom}`}
            </Typography.Title>
          </FlexContainer>
          <Tag color="geekblue">{item.category}</Tag>
          {item.subcategory && <Tag color="blue">{item.subcategory}</Tag>}
          <br /> <br />
          <Typography.Paragraph type="secondary" style={{ flexGrow: 1 }}>
            {item.description}{' '}
          </Typography.Paragraph>
          {item.options && (
            <List bordered header={<Typography.Text type="secondary">Options</Typography.Text>}>
              {item.options.map((option) => (
                <List.Item
                  key={_.uniqueId()}
                  extra={[
                    <Typography.Text style={{ color: '#096dd9' }}>
                      {option.price ? `${currencyFormat(option.price, '$')}` : ''}
                    </Typography.Text>,
                  ]}
                >
                  <List.Item.Meta title={option.name} description={option.description} />
                </List.Item>
              ))}
            </List>
          )}
        </Card>
      </List.Item>
    )
  }

  return (
    <StyledContainer fluid>
      <StyledContainer fluid relax>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 1,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={dishes}
          renderItem={handleRenderListItem}
          style={{ display: 'flex' }}
        />
      </StyledContainer>
    </StyledContainer>
  )
}

export default CategoryView
